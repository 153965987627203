<template>
    <v-container fluid class="backgroundGrey">
        <v-row>
            <v-col cols="10" xl="6" lg="6" md="6" sm="8" class="mx-auto">
    
                <h1 class="text-center text-h5 text-sm-h4 py-8">Mentions Légales</h1>
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Informations administratives</h2>
                <v-row class="py-6">
                    <v-col cols="10">
                        <p class="text-body-2 text-sm-body-1 text-md-h6  py-sm-4">
                            Pulse Group<br>Société par actions simplifiée<br>
                            <!--R.C.S. "METZ TI 824 081 673"<br>-->
                            Capital social: 30 000 € <br>
    
    
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="text-body-2 text-sm-body-1 text-md-h6  py-sm-4">
                            <strong>Siège social:</strong><br>38, route de Saint Hilaire – 44190 CLISSON <br>
                            <strong>Contact:</strong><br>01 83 71 47 14 – contact@pulsegroup.fr <br>
    
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p class="text-body-2 text-sm-body-1 text-md-h6  py-sm-4">
    
                            Directeur de la publication : Xavier BAYLE.
                        </p>
                        <p class="text-body-2 text-sm-body-1 text-md-h6  py-sm-4">
    
                            Responsable du site web : Aidalinfo
                        </p>
                    </v-col>
    
                </v-row>
    
    
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Les mentions légales obligatoires</h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    Pour être dans les règles, le site internet doit obligatoirement faire figurer les mentions légales. Toutes ces informations seront accessibles sur le site internet afin de ne pas être pénalisé par la Direction Générale de la Concurrence, de la Consommation
                    et de la Répression des Fraudes.
                </p>
    
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Les cookies</h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    L’internaute doit être informé des finalités des cookies utilisés et il a la possibilité de s’y opposer. Les paramètres des cookies sont présents lors de la consultation d'un site web ou d’une application mobile depuis n'importe quel terminal (ordinateur,
                    smartphone, tablette). Le terme de « cookie » est utilisé pour le web, il s’agit d’un traceur. Ils vont permettre de récolter les données des visiteurs (numéro de série, adresse MAC, identifiant unique de terminal etc.).
                </p>
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">
                    Protection des données personnelles
                </h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    Notre site n’est pas un site marchand mais il peut être contraint à faire une déclaration auprès de la CNIL (Commission Nationale de l'Informatique et des Libertés), pour récupérer les données sur nos clients ou prospects. La CNIL veille à la protection
                    des données personnelles contenues dans les fichiers et traitements informatiques ou papiers, aussi bien publics que privés. Il faut également prendre en compte la RGPD. Le sigle RGPD signifie Règlement Général sur la Protection des Données,
                    elle permet d’encadrer le traitement des données personnelles des entreprises, aux organismes publics et aux associations quelles que soient leur taille ou leur activité sur le territoire de l’Union européenne.
                </p>
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Les droits concernant la protection des données personnelles</h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    D’après le site economie.gouv.fr, nos droits et nos obligations concernant la protection des données personnelles sont les suivants :
    
                    <ul>
                        <li class="py-5">de demander des informations sur le traitement de vos données à caractère personnel.</li>
                        <li class="py-5">d’obtenir l’accès aux données à caractère personnel détenues à votre sujet.</li>
                        <li class="py-5">de demander que les données à caractère personnel incorrectes, inexactes ou incomplètes soient corrigées.
                        </li>
                        <li class="py-5">de demander que les données à caractère personnel soient effacées lorsqu’elles ne sont plus nécessaires ou si leur traitement est illicite.</li>
                        <li class="py-5">de vous opposer au traitement de vos données à caractère personnel à des fins de prospection ou pour des raisons liées à votre situation particulière.</li>
                        <li class="py-5">de demander la limitation du traitement de vos données à caractère personnel dans des cas précis.
                        </li>
                        <li class="py-5">de récupérer vos données personnelles, dans un format utilisé et lisible par machine, pour un usage personnel ou pour les transférer à un autre organisme.</li>
                        <li class="py-5">de demander que les décisions fondées sur un traitement automatisé qui vous concernent ou vous affectent de manière significative et fondées sur vos données à caractère personnel soient prises par des personnes physiques et non uniquement
                            par des ordinateurs. Dans ce cas, vous avez également le droit d’exprimer votre avis et de contester lesdites décisions.</li>
                        <li class="py-5">En cas de dommage matériel ou moral lié à la violation du RGPD, vous disposez d’un droit de recours. Vous pouvez déposer une réclamation auprès de la Commission nationale Informatique et libertés (CNIL) ou introduire une action collective
                            en faisant notamment appel aux associations nationales agréées de défense des consommateurs.</li>
                    </ul>
                </p>
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Les droits concernant la protection des données personnelles</h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">Nos obligations en temps qu’entreprise sont :
                    <ul>
                        <li class="py-5">de respecter le principe de protection des données personnelles et de la vie privée imposées par le règlement, dès la conception de tout projet.</li>
                        <li class="py-5">de recenser les traitements qu’elles mettent en œuvre dans un registre des traitements.</li>
                        <li class="py-5">d’être en capacité de prouver que les traitements de données à caractère personnel mis en œuvre respectent les règles applicables, notamment via l’adhésion à des codes de conduite et l’obtention d’une certification.</li>
    
                        <li class="py-5">de notifier toute violation de données à caractère personnel par le responsable de traitement et le sous-traitant aux autorités et aux personnes concernées.</li>
                        <li class="py-5">de réaliser une étude d’impact sur la vie privée pour les traitements à risque.</li>
                        <li class="py-5">de désigner un délégué à la protection des données pour les organismes publics et les entreprises dont l’activité principale les amène à réaliser un suivi régulier et systématique des personnes à grande échelle ou encore des organismes
                            qui traitent des données dites « sensibles » ou relatives à des condamnations pénales et infractions.
                        </li>
                        <li class="py-5">de s’assurer que les personnes sont informées, de manière claire et concise, de la durée de conservation des données, de l’existence de profilage, de leurs droits et des voies de recours disponibles.</li>
                        <li class="py-5">de permettre aux personnes dont les données sont traitées d’exercer leurs droits (à l’oubli, à la portabilité des données, de limitation… etc.).</li>
    
                    </ul>
                </p>
                <!--<h1 class="text-h5 text-sm-h4 py-8">Charte des données personnelles</h1> -->
                <h2 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4">Les droits concernant la protection des données personnelles</h2>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">Nos obligations en temps qu’entreprise sont : </p>
                <p class="text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    <ul>
                        <li class="py-5">de respecter le principe de protection des données personnelles et de la vie privée imposées par le règlement, dès la conception de tout projet ;</li>
                        <li class="py-5">de recenser les traitements qu’elles mettent en œuvre dans un registre des traitements ;</li>
                        <li class="py-5">d’être en capacité de prouver que les traitements de données à caractère personnel mis en œuvre respectent les règles applicables, notamment via l’adhésion à des codes de conduite et l’obtention d’une certification ;</li>
    
                        <li class="py-5">de notifier toute violation de données à caractère personnel par le responsable de traitement et le sous-traitant aux autorités et aux personnes concernées ;</li>
                        <li class="py-5">de réaliser une étude d’impact sur la vie privée pour les traitements à risque ;</li>
                        <li class="py-5">de désigner un délégué à la protection des données pour les organismes publics et les entreprises dont l’activité principale les amène à réaliser un suivi régulier et systématique des personnes à grande échelle ou encore des organismes
                            qui traitent des données dites « sensibles » ou relatives à des condamnations pénales et infractions ;
                        </li>
                        <li class="py-5">de s’assurer que les personnes sont informées, de manière claire et concise, de la durée de conservation des données, de l’existence de profilage, de leurs droits et des voies de recours disponibles ;</li>
                        <li class="py-5">de permettre aux personnes dont les données sont traitées d’exercer leurs droits (à l’oubli, à la portabilité des données, de limitation… etc.).</li>
    
                    </ul>
    
                </p>
                
                <p class="text-center text-body-2 text-sm-body-1 text-md-h6 py-sm-4">
                    source: <a href="https://www.economie.gouv.fr/dgccrf/Publications/Vie-pratique/Fiches-pratiques/protection-des-donnees-personnelles-quels-sont-droits
                    ">économie.gouv</a>
                </p>
    
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "mentionsLegales",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>